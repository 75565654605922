@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;700&display=swap');
html { 
  height: 100%; 
  overflow:auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body { height: 100%; }
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #888; 
}
* {
  transition-duration: 0.3s;
}
body {
  margin: 0;
  font-family: 'Quicksand' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#leftPanel {
  position: fixed;
  width: 460px;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  top: 0px;
  left: 0px;
}
.mainContent {
  margin-left: 460px;
  width: calc(100% - 460px);
  overflow: hidden;
  min-height: 100vh;
}
.mainForm {
  width: 80%;
  max-width: 540px;
  min-height: 60vh;
  display: block;
  margin: auto;
  margin-top: 100px;
  text-align: left;
  position: relative;
}
.mainFormNoMargin {
  width: 80%;
  max-width: 540px;
  min-height: 60vh;
  display: block;
  margin: auto;
  margin-top: 80px;
  text-align: left;
}
h1, h3 {
  color: #434c5f;
  font-family: "Open sans";
  font-weight: 100;
  text-align: left;
}
h1 {
  font-size: 28px;
  color: gray;
}
h3 {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  color: #596377;
  margin-bottom : 40px;
}
#productDescription {
  font-weight: bold;
}
.simpleInput {
  margin-top: 20px;
  position: relative;
}
.expirationInput {
  margin-top: 20px;
  width: 200%;
}
.cvcInput {
  position: absolute;
  top: 247px;
  left: 190px;
  width: 200%;
}
.inputBar {
  display: block !important;
  width: 90%;
  border: 1px solid #d4d9e2 !important;
  border-radius: 30px;
  height: 18px;
  padding: 15px;
  padding-left: 18px;
  font-size: 14px;
  color: #596377;
  outline: 0;
  background: transparent;
  margin-left: -5px;
  transition: .3s;
}
.disabledInputBar {
  display: none !important;
}
.inputBarRed {
  border: 2px solid rgba(255,0,0,0.7) !important;
}
.selectBar {
  background: white;
  padding: 15px;
  border-radius: 30px;
  color: rgba(0,0,0,0.6);
  border: 1px solid #d4d9e2 !important;
  width: calc(94% - 20px);
  min-width: 200px;
  font-size: 14px;
  outline: none !important;
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='20' viewBox='0 0 24 24' width='20' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: 15px;
  margin-left: -5px;
  cursor: pointer;
}
.autocompleteInputBar {
  background: white;
  font-family: 'Quicksand';
  padding: 15px;
  border-radius: 30px;
  color: rgba(0,0,0,0.6);
  border: 1px solid #d4d9e2 !important;
  width: calc(94% - 20px);
  min-width: 200px;
  font-size: 14px;
  outline: none !important;
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;
  margin-left: -5px;
  cursor: pointer;
}
.noResultsText {
  color: rgba(0,0,0,0.4) !important;
  background: white !important;
  cursor: default !important;
}
.shortInputBar {
  display: inline-block !important;
  width: 10%;
  border: 1px solid #d4d9e2;
  border-radius: 30px;
  height: 18px;
  padding: 15px;
  font-size: 14px;
  color: #596377;
  outline: 0;
  background: transparent;
  margin-right: 10px;
  margin-left: -5px;
}
.mediumInputBar {
  display: inline-block !important;
  width: calc(35% - 106px);
  border: 1px solid #d4d9e2;
  border-radius: 30px;
  height: 18px;
  padding: 15px;
  font-size: 14px;
  color: #596377;
  outline: 0;
  background: transparent;
  margin-right: 10px;
  margin-left: -5px;
}
.selectMenu {
  background: white;
  position: absolute;
  height: 150px;
  padding: 4px;
  width: calc(94% - 8px);
  left: 0%;
  top: 84px;
  border: 1px solid #e4e9e2;
  box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.13);
  border-radius: 8px;
  overflow-y: scroll;
  z-index: 1;
}
.selectMenu div {
  padding: 6px 10px;
  margin: 0px;
  color: rgba(0,0,0,0.7);
}
.selectMenu div:hover {
  background: rgba(0,0,0,0.06);
  cursor: pointer;
}
.optionButton {
  display: inline-block !important;
  min-width: 82px;
  border: 1px solid #d4d9e2;
  border-radius: 30px;
  height: 50px;
  padding: 15px;
  font-size: 14px;
  color: #596377;
  outline: 0;
  background: transparent;
  margin-right: 12px;
  cursor: pointer;
  margin-left: -5px;
  margin-bottom: 3px;
}
.optionButtonSelected {
  display: inline-block !important;
  min-width: 82px;
  border: 2px solid #778;
  border-radius: 30px;
  height: 50px;
  padding: 15px;
  font-size: 14px;
  color: #596377;
  outline: 0;
  background: #b4b9c222;
  margin-right: 12px;
  cursor: pointer;
  margin-left: -5px;
  margin-bottom: 3px;
}
.optionButton:hover {
  background: rgba(0,0,100,0.07);
}
.yearInputBar {
  width: 20% !important;
}
.inputBar:focus, .shortInputBar:focus {
  border-color: #8c96a9;
}
.inputBar::placeholder, .shortInputBar::placeholder, .mediumInputBar::placeholder {
  opacity: 0.5 !important;
}
.simpleLabel {
  display: block;
  font-family: brandon-grotesque,"Open Sans",open-sans,sans-serif;
  font-weight: 700;
  font-size: 11px;
  color: #434c5e;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.simpleButton {
  display: block;
  margin: 40px auto;
  background: #333;
  border-radius: 100px;
  height: 50px;
  line-height: 50px;
  padding: 0 60px;
  font-family: brandon-grotesque,"Open Sans",open-sans,sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-appearance: none;
  border: none;
  outline: none;
}
.disabledButton {
  display: block;
  margin: 40px auto;
  background: lightgray;
  border-radius: 100px;
  height: 50px;
  line-height: 50px;
  padding: 0 60px;
  font-family: brandon-grotesque,"Open Sans",open-sans,sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: default;
  -webkit-appearance: none;
  border: none;
  opacity: 0.5;
  outline: none;
}
.simpleButton:hover {
  box-shadow: 0 3px 15px 0 rgba(0,0,0,.3);
  opacity: 0.9;
}
.midHeader {
  margin-top: 80px;
  margin-bottom: 20px;
  font-weight: 500 !important;
}
.hidden {
  opacity: 0 !important;
  display: none;
}
@media (max-width: 1100px) {
  #leftPanel {
    display: none;
  }
  .checkoutLoaderContainer {
    width: 100vw !important;
  }
  .mainContent {
    margin-left: 0px;
    width: 100%;
  }
}


.radioButtonContainer {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-right: 46px;
  margin-top: 6px;
  margin-left: 0px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #596377;
}
.radioButtonContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
  border-radius: 50%;
}
.radioButtonContainer:hover input ~ .checkmark {
  background-color: #ccc;
}
.radioButtonContainer input:checked ~ .checkmark {
  background-color: #434c5e;
  opacity: 0.8;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.radioButtonContainer input:checked ~ .checkmark:after {
  display: block;
}
.radioButtonContainer .checkmark:after {
 	top: 7px;
	left: 7px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}
.frame-area {
  display: block;
  width: 100%;  /* RESPONSIVE WIDTH */
  max-width: 100vw;
  height: 99vh;
  overflow: auto;  /* EDIT TO hidden FOR NO SCROLLBAR */
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

iframe .App {
  background: white !important;
}


/* Checkout */
.rccs {
  position: absolute;
  right: 0px;
  transform: scale(0.7);
  top: -20px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.totalDiv {
  height: 50px;
  width: 95%;
  margin-top: 30px;
  border-top: 1px solid rgba(0,0,0,.1);
}
.totalAmount {
  color: #434c5e;
  float: right;
  font-weight: 600;
  margin-right: 30px;
  margin-top: 15px;
}
.labelTotalAmount {
  color: #434c5e;
  float: left;
  font-weight: 600;
  margin-left: 30px;
  margin-top: 15px;
}
#PaymentForm {
  margin-top: -20px;
}
@media (max-width: 600px) {
  .rccs {
    right: 0px;
    transform: scale(0.5);
    transform-origin: right;
  }
}
@media (max-width: 380px) {
  .rccs {
    right: 0px;
    transform: scale(0.4);
    transform-origin: right;
  }
  .rccs__number, .rccs__name, .rccs__expiry {
    display: none;
  }
  .rccs__issuer {
    transform: scale(2);
    transform-origin: right top;
  }
}
.cardOwner {
  color: gray;
  font-size: 15px;
  display: inline-block;
  margin-top: -10px;
}
.editCardOwner {
  display: inline-block;
  margin-left: 20px;
  background: transparent;
  color: blue;
  border: 1px solid blue;
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: -10px;
}
.editCardOwner:hover {
  border-color: gray;
}
.goBackButton {
  position: absolute;
  padding-left: 30px;
  top: -30px;
  background: transparent;
  border: none;
  color: black;
  cursor: pointer;
  opacity: 0.3;
}
.goBackButton:hover {
  opacity: 0.6;
}
.goBackIcon {
  background-image: url("https://icons-for-free.com/iconfiles/png/512/arrow+left+chevron+chevronleft+left+left+icon+icon-1320185731545502691.png");
  height: 18px;
  width: 20px;
  background-size: contain;
  position: absolute;
  left: 0px;
  top: 0px;
}
#spinner {
  box-sizing: border-box;
  stroke: lightgray;
  stroke-width: 3px;
  transform-origin: 50%;
  animation: line 1.6s cubic-bezier(0.4, 0.0, 0.2, 1) infinite, rotate 1.6s linear infinite;
}
.loaderContainer {
  width: 100vw;
  height: calc(50vh + 64px);
  z-index: 1;
  background: white;
  position: fixed;
  padding-top: calc(50vh - 64px);
  overflow: hidden;
  transition: .5s;
}
@keyframes rotate {
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(450deg);
  }
}
@keyframes line {
  0% {
    stroke-dasharray: 2, 85.964;
    transform: rotate(0);
  }
  50% {
    stroke-dasharray: 65.973, 21.9911;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 2, 85.964;
    stroke-dashoffset: -65.973;
    transform: rotate(90deg);
  }
}
.space20 {
  height: 20px;
}
.footer {
  background: rgba(0,0,0,0.04);
  min-height: 140px !important;
  margin-top: 120px;
  width: 90%;
  padding-left: 8%;
  padding-right: 2%;
}
.footerColumn {
  height: 100%;
  width: 40%;
  display: inline-block;
  text-align: left;
  vertical-align: top;
  color: rgba(0,0,0,0.6);
  font-weight: 200;
  font-size: 15px;
  padding: 4%;
  overflow: hidden;
}
.footerColumn p {
  color: rgba(0,0,0,0.8);
  max-width: 350px;
}
.footerColumn * {
  vertical-align: top;
}
@media (max-width: 1100px) {
  .footerColumn {
    width: 90%;
    margin: 0px;
    margin-bottom: 10px;
    margin-left: -15px;
    /* padding: 5%; */
  }
}

/* SUCCESS ANIMATION */
@-webkit-keyframes scaleAnimation {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scaleAnimation {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes drawCircle {
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes drawCircle {
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes drawCheck {
  0% {
    stroke-dashoffset: 36px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes drawCheck {
  0% {
    stroke-dashoffset: 36px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.checkoutLoaderContainer {
  width: calc(100vw - 467px);
  height: 100vh;
  position: fixed;
  background: white;
  z-index: 999;
  top: 0px;
  right: 0px;
  padding-top: calc(50vh - 125px);
  text-align: center;
}
.checkoutLoaderContainer svg {
  display: block;
  margin: auto;
  margin-bottom: 20px;
}
.checkoutLoaderContainer circle {
  stroke-width: 1px !important;
}
#successAnimation {
  display: block;
  margin: auto;
}
#successAnimationCircle {
  stroke-dasharray: 151px 151px;
  stroke: #37c881ff;
}
#successAnimationCheck {
  stroke-dasharray: 36px 36px;
  stroke: #37c881ff;
}
#successAnimationResult {
  fill: #37c881ff;
  opacity: 0;
}
#successAnimation.animated {
  -webkit-animation: 1s ease-out 0s 1 both scaleAnimation;
          animation: 1s ease-out 0s 1 both scaleAnimation;
}
#successAnimation.animated #successAnimationCircle {
  -webkit-animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
          animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
}
#successAnimation.animated #successAnimationCheck {
  -webkit-animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
          animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
}
#successAnimation.animated #successAnimationResult {
  -webkit-animation: 0.3s linear 0.9s both fadeIn;
          animation: 0.3s linear 0.9s both fadeIn;
}
.SuccessDiv {
  padding-top: 10vh;
}
.SuccessDiv h2 {
  width: 100% !important;
  text-align: center !important;
  font-size: 30px;
  color: black;
  margin-top: 0px;
}
.SuccessDiv p {
  width: 100% !important;
  text-align: center !important;
  color: gray;
}
.snackbarContainer {
  background: rgba(0,0,0,0.2);
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 999;
}
.snackbar {
  background: white;
  border-radius: 3px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  margin: auto;
  margin-top: calc(50vh - 150px);
  overflow: hidden;
}
.snackbar p {
  min-height: 60px;
  text-align: left;
  padding: 0px 10px;
}
.snackbar button {
  background: rgba(0,170,80,0.7);
  color: white;
  border: none;
  padding: 10px 30px;
  border-radius: 30px; 
  float: right;
  cursor: pointer;
  font-weight: 800;
  margin-right: 0px;
}
#errorMsg {
  color: red;
  position: absolute;
  transform: translateY(-10px);
}
.terms {
  color: gray;
  margin-top: 40px;
  font-size: 14px;
}
.terms *, .inputCheckbox * {
  display: inline-block;
  margin-top: 0px;
}
.terms input, .inputCheckbox input {
	-webkit-appearance: none;
	background-color: white !important;
	border: 1px solid #cacece;
	padding: 8px;
	border-radius: 2px;
	display: inline-block;
  position: relative;
  margin-right: 5px;
  transform: translateY(6px);
  z-index: 0 !important;
  outline: none;
  cursor: pointer;
}
.terms input:active, .terms input:checked:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

.terms input:checked, .inputCheckbox input:checked {
	background-color: rgb(119, 190, 119) !important;
	border: 1px solid rgb(119, 190, 119);
  color: #99a1a7;
  overflow: hidden;
}
.terms input:checked:after, .inputCheckbox input:checked:after {
	content: '\2714';
	font-size: 14px;
	position: absolute;
	top: 0px;
	left: 3px;
  color: white;
  outline: none !important;
}
.phoneFormat {
  font-size: 14px;
  color: #596377;
  display: inline-block;
  margin-right: 15px;
}
.msgExtra {
  color: blue;
  margin-top: 6px;
  margin-bottom: 20px;
}
.miniInput {
  width: calc(50% - 31px) !important;
  margin-right: 30px;
  display: inline-block;
}
.addStudentButton {
  font-size: 13px;
  cursor: pointer;
}
.addStudentButton:hover {
  color: blue !important;
  text-decoration: underline;
}
@media (max-width: 1000px) {
  .editNameButton {
    font-size: 13px;
    cursor: pointer;
    position: absolute;
    right: calc(5% + 4px);
    top: 103px !important;
  }
}
.editNameButton {
  font-size: 13px;
  cursor: pointer;
  position: absolute;
  right: calc(5% + 4px);
  top: 73px;
}
.editNameButton:hover {
  color: blue !important;
  text-decoration: underline;
}
.contactWallContainer {
  margin-top: 60px;
  background: rgba(0,0,0,0.02);
  border: 1px solid rgba(0,0,0,.1);
  padding: 20px 40px;
  border-radius: 6px;
  color: rgba(0,0,0,0.6);
  width: calc(90% - 56px);
}
.contactWallContainer h1 {
  font-size: 24px;
  font-weight: 600;
  color: rgba(0,0,0,0.8) !important;
}
.contactWallContainer button {
  margin-top: 40px;
  margin-bottom: 30px;
}
.paymentClarification {
  color: gray;
  font-size: 15px;
  vertical-align: middle;
  padding-top: 8px;
  display: inline;
}
.paymentClarificationSpan {
  display: inline;
  background: rgba(0,0,255,0.7);
  margin-right: 6px;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 800;
  border-radius: 18px;
  padding: 1px 7px;
  color: white;
}
.expirationSpan {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 14px solid rgba(255,80,80,0.8);
  vertical-align: middle;
  margin-right: 9px;
  font-size: 12px;
  font-weight: 800;
  color: white;
  padding-right: 3px;
  border-radius: 3px;
}
.paymentClarificationDiv {
  margin-top: 16px;
  margin-bottom: 10px;
  background: rgba(0,0,0,0.03);
  padding: 12px;
  padding-top: 10px;
  border-radius: 8px;
  margin-left: -6px;
  width: 94%;
}
.expiryWallContainer {
  width: 100vw;
  height: 100vh;
  background: white;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
}
.expiryWallContainer h1 {
  text-align: center;
  margin-top: 20vh;
}
.extraText {
  color: gray;
  display: inline-block;
  width: 450px;
  font-size: 14px;
  margin-top: 0px;
}